<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-row>
            <b-col cols="12" xl="7" md="7">
                <b-card no-body>
                    <div class="text-center">                        
                        <b-button @click="$router.push({name: 'apps-inventories-transfer-vouchers-add'})" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="m-1">
                            Solicitar Material
                        </b-button>
                        <b-button @click="$router.push({name: 'apps-inventories-transfer-vouchers-send'})" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="m-1">
                            Enviar Material
                        </b-button>
                        <b-button @click="$router.push({name: 'apps-inventories-transfer-vouchers-receive'})" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="m-1">
                            Recibir Material
                        </b-button>
                        
                        <b-button @click="getTransfers" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="m-1">
                            <feather-icon icon="RefreshCwIcon" size="18" />
                            Refrescar
                        </b-button>
                    </div>

                    <b-row>
                        <b-col cols="12">
                            <div class="m-1 text-center text-uppercase">
                                <h3>Listado de Vales</h3>
                            </div>
                            <div class="table-responsive">
                                <div class="position-relative b-table-responsive mb-0 text-small small b-table-sticky-header table-responsive">
                                    <table class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single">
                                        <thead>
                                            <tr>
                                                <th>Identificador</th>
                                                <th>Folio</th>
                                                <th>Fecha</th>
                                                <th>Chofer</th>
                                                <th>Solicita</th>
                                                <th>Almacen Solicita</th>
                                                <th>Almacen Surte</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="totalRecords > 0">
                                            <tr v-for="(item, index) in transfers" :key="index" @click="selectRow(item, index)"
                                                :class="{ 
                                                    'selected-row': isSelected(index),
                                                }">
                                                <td>{{ item.docto_Ve_Id }}</td>
                                                <td>{{ item.folio }}</td>
                                                <td class="text-capitalize">{{ customDate(item.fecha, 'DD MMM Y') }}</td>
                                                <td>{{ item.webOperadores ? item.webOperadores.nombre : '--' }} {{ item.webOperadores? item.webOperadores.apellidos : '' }}</td>
                                                <td>{{ item.applicationUser.fullName }}</td>
                                                <td>{{ item.almacen_Origen }}</td>
                                                <td>{{ item.almacen_Destino }}</td>
                                                <td>
                                                    <b-button v-if="item.enviadas <= 0 || item.puedeCerrar" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" size="sm" 
                                                        @click.stop="deactiveVoucher(item, index)">
                                                        <feather-icon icon="XIcon" /> Cerrar
                                                    </b-button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="8" class="text-center">
                                                    <h6 class="mt-2">No se encontraron resultados</h6>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- Pagination -->
                            <div v-if="totalRecords > 0" class="mx-2 mb-2">
                                <b-row>
                                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                        <span class="text-muted">{{ resolvePaginationTranslate(dataMeta) }}</span>
                                    </b-col>
                                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                        <div class="mt-2 demo-spacing-0">
                                            <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize"/>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <!-- Details -->
            <b-col v-if="details.length > 0" ref="transferActions" cols="12" md="5" xl="5" class="transfer-actions">
                <b-card no-body class="border-primary">
                    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
                        <h5 class="mb-0"> {{ isActive ? 'VALE ACTIVO' : 'VALE CERRADO' }} CON FOLIO: {{ details[0].folio }} </h5>
                        <b-badge variant="light-primary">
                            {{ isActive ? 'ABIERTO' : 'CERRADO' }}
                        </b-badge>
                        <small class="text-muted w-100">{{ formatDate(details[0].fecha_Alta) }}</small>
                    </b-card-header>

                    
                    <div class="m-1">
                        <div v-if="details.length > 0" class="demo-spacing-0">
                            <b-alert :show="details.length > 0" variant="warning">
                                <div class="alert-body">
                                    <feather-icon class="mr-25" icon="StarIcon"/>
                                    <span class="ml-25">
                                        <strong>{{ details[0].almacen_Origen }}</strong> RECIBE MATERIAL DE <strong>{{ details[0].almacen_Destino }}</strong>
                                    </span>
                                </div>
                            </b-alert>
                        </div>

                        <ul class="list-unstyled my-1">
                            <li class="my-25">
                                <span class="align-middle text-uppercase"><strong>FOLIO</strong> {{ details[0].folio }}</span>
                            </li>
                            <li>
                                <span class="align-middle text-uppercase"><strong>ASIGNADO A CHOFER</strong> {{ details[0].chofer }}</span>
                            </li>
                        </ul>
                    </div>

                    <global-articles :articles="details" :loading="loading" :isColumnDetails="true" :isSticky="true" />
                    
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BCardHeader, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import store from '@/store';
import router from '@/router'
import GlobalArticles from '@/@core/components/GlobalArticles.vue'
import TransferVouchersProvider from '@/providers/TransferVouchers'
const TransferVouchersResource = new TransferVouchersProvider()


export default {
    name: 'TransferVouchers',
    components: {
        BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BCardHeader, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
        BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
        BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BListGroup, BListGroupItem,

        //
        flatPickr,
        vSelect,
        GlobalArticles,
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    data() {
        return {
            loading: false,
            transfers: [],
            details: [],
            currentPage: 1,
            pageSize: 10,
            totalPages: 0,
            totalRecords: 0,
            selectedRowIndex: -1,
        }
    }, 
    computed: {
        dataMeta() {
            const localItemsCount = _.size(this.transfers);
            return {
                from:
                    this.pageSize * (this.currentPage - 1) +
                    (localItemsCount ? 1 : 0),
                to: this.pageSize * (this.currentPage - 1) + localItemsCount,
                of: this.totalRecords,
            };
        },
        isActive() {
            //Encontrar en details si hay con la propiedad cerrada = false
            return this.details.find(item => item.cerrada === false)
        }
    },   
    async mounted() {
        await this.getTransfers()
    },
    methods: {
        async selectRow(item, index) {
            this.loading = true
            this.selectedRowIndex = index;

            //Get Transfer Details
            try {                
                const { data } = await TransferVouchersResource.getAllByDoctoVeId(item.docto_Ve_Id)
                if (data.isSuccesful) {
                    this.details = data.data                   
                } else {
                    this.danger(data.message)
                }

            }catch(error) {
                this.handleResponseErrors(e)
            }finally {
                this.loading = false
            }
        },
        isSelected(index) {
            // Determina si la fila en el índice dado está seleccionada
            return index === this.selectedRowIndex
        },
        async getTransfers() {
            
            this.selectedRowIndex = -1
            this.details = []
            const query = {
                fechaInicio: null,
                fechaFin: null,
                numeroPagina: this.currentPage,
                tamanoPagina: this.pageSize,
                tipoMov: 'P',
            }
            try {                
            
                this.loading = true
                const { data } = await TransferVouchersResource.getByFilters(query)
                this.loading = false

                this.transfers = data.data
                this.totalRecords = data.totalRecords

            } catch (error) {
                this.loading = false
                this.handleResponseErrors(error)
            } finally {
                this.loading = false
            }
        },
        deactiveVoucher(item, index) {
            this.details = []
            this.selectedRowIndex = -1

            this.$swal({
                title: '¿Estás seguro?',
                html: `Cerrar el vale con folio ${item.folio}, <p>¡No podrás revertir esta acción!</p>`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: `Continuar`,
                cancelButtonText: `Cancelar`,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await TransferVouchersResource.deactiveVoucher(item.docto_Ve_Id)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.success(data.message)
                            this.transfers.splice(index, 1)
                        } else {
                            this.$swal.showValidationMessage(
                                `Request failed: ${data.message}`
                            );
                        }
                    }catch(e) {
                        this.$swal.showValidationMessage(
                            `Request failed: ${e}`
                        );
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            });
        },
    },
    watch:{
        currentPage(value) {
            this.getTransfers();
        },
        pageSize(value) {
            this.getTransfers();
        },
    }
}

</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/pages/app-invoice.scss';

</style>
<style>
.b-table th {
    font-size: 9px !important; /* Ajusta el tamaño de fuente según tu necesidad */
}

.b-table td {
    font-size: 10px !important; /* Ajusta el tamaño de fuente según tu necesidad */
}

.b-table-sticky-header {
    overflow-y: auto;
    max-height: 450px;
}

.selected-row {
    background-color: #007bff; /* Color de fondo para la fila seleccionada */
    color: white; /* Color de texto para la fila seleccionada */
}
</style>